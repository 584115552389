jQuery(document).ready(function ($) {

  //Homepage Banner
  $('.cycle-slideshow').cycle('pause');

  $('.prev-next-pager .prev, .prev-next-pager .next').on('keypress', function (e) {
    if (e.keyCode == 13) {
      $('.cycle-slideshow').cycle('next', 'prev');
    }
  });

  //Featured Events / Featured News
  $('h3.event-title').each(function (e) {
    $(this).nextUntil('.event-blurb').andSelf().wrapAll('<div class="event-wrap"/>');
  });

  $('.events, .news-items').addClass('owl-carousel');
  $('.event, .news-item').addClass('item');

  //Featured Events / Featured News Accessibility
  $('.news-items').attr('aria-label', 'news items');
  $('.events').attr('aria-label', 'event items');
  $(window).load(function () {
    $('.owl-dots .owl-dot').attr('aria-label', 'next item');
  });

  $('.owl-carousel').owlCarousel({
    loop: false,
    margin: 15,
    autoplay: false,
    responsiveClass: true,
    responsive: {
      0: {
        loop: false,
        items: 1,
        nav: true,
        navText: ["<span class='previous'>", "<span class='next'>"],
        dots: true,
        dotsEach: true,
        stagePadding: 0,
        margin: 10
      },
      375: {
        loop: false,
        items: 1,
        nav: true,
        navText: ["<span class='previous'>", "<span class='next'>"],
        dots: true,
        dotsEach: true,
        stagePadding: 30,
        margin: 30
      },
      991: {
        items: 3,
        nav: false,
        margin: 50
      }
    }
  });

  //Hide transition arrows for banner slider if there is only one slide
  if ($('.cycle-pager > *').length <= 1) {
    $('.prev-next-pager').hide();
  }
});